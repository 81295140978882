import axios from "@/axios"

export async function getOrdersList(params) {
    const url = `/order-management?${params}`;
    return await axios.get(url)
}

export async function getTenantsList() {
    const url = "/tenant"
    return await axios.get(url)
}

export async function getTenantTax(tenantId) {
    const url  = `/tax/tax-tenant/${tenantId}`
    return await axios.get(url)
}

export async function createOrder(payload) {
    const url = "/order-management"
    return await axios.post(url, payload)
}

export async function getTenantCreditPacks(tenantId) {
    const url = `/order-management/tenant/credit-packs?tenant_id=${tenantId}`
    return await axios.get(url)
}

export async function getOrderNumber(tenantId) {
    const url = `/order-management/orders/order-number?tenant_id=${tenantId}`
    return await axios.get(url)
}

export async function getOrderDetails(orderId) {
    const url = `/order-management/${orderId}`
    return await axios.get(url)
}

export async function getOrderCreditPacks(orderId) {
    const url = `/order-management/${orderId}/credit-packs`
    return await axios.get(url)
}

export async function orderStatus(payload, orderId) {
    const url = `/order-management/${orderId}/status`
    return await axios.post(url,payload)
}

export async function updateOrder(payload,orderId) {
    const url = `/order-management/${orderId}`
    return await axios.put(url, payload)
}

export async function getCurrencyDetail(currency_id) {
    const url = `/currency/${currency_id}`;
    return await axios.get(url);
}