var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"p-1"},[_c('sub-header',{attrs:{"backButton":true}})],1),_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"shadow-md relative rounded-2xl bg-white flex gap-6 flex-col"},[_c('div',{staticClass:"p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"},[_vm._v(" Create Currency ")]),_c('div',{staticClass:"py-3 px-6"},[_c('FormulateForm',{scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [_c('div',{staticClass:"grid grid-cols-3 gap-x-6"},[_c('div',{staticClass:"h-6"},[_c('h2',{staticClass:"mb-1 mt-1"},[_vm._v("Currency Code")]),_c('Dropdown',{attrs:{"placeholder":"currency codes","options":_vm.currencyCodes,"searchable":"","colors":{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                },"value":_vm.currencyCode,"config":{ label: 'currency_code', trackBy: 'currency_code' },"maxWidth":"100%","minWidth":"100%","minHeight":"20%","loading":_vm.loadingCurrency},on:{"input":(value) => _vm.updateCode(value)}})],1),_c('FormulateInput',{staticClass:"w-full",attrs:{"label":"Currency Symbol","placeholder":"currency symbol","validation":"required","type":"text","disabled":"","element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes)},model:{value:(_vm.currencyInformation.currency_symbol),callback:function ($$v) {_vm.$set(_vm.currencyInformation, "currency_symbol", $$v)},expression:"currencyInformation.currency_symbol"}}),_c('FormulateInput',{staticClass:"w-full",attrs:{"validation":"required","label":"Currency Name","placeholder":"currency name","type":"text","disabled":"","element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes)},model:{value:(_vm.currencyInformation.currency_name),callback:function ($$v) {_vm.$set(_vm.currencyInformation, "currency_name", $$v)},expression:"currencyInformation.currency_name"}}),_c('div',{staticClass:"h-6"},[_c('h2',{staticClass:"mb-1 mt-1"},[_vm._v("Decimal Places")]),_c('Dropdown',{attrs:{"placeholder":"decimal places","options":_vm.decimalPlaces,"searchable":"","colors":{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                },"value":_vm.decimalValue,"config":{ label: 'decimal', trackBy: 'decimal' },"maxWidth":"100%","minWidth":"100%","minHeight":"20%"},on:{"input":(value) => _vm.updateDecimalValue(value)}})],1),_c('div',{staticClass:"h-6"},[_c('h2',{staticClass:"mb-1 mt-1"},[_vm._v("Format")]),_c('Dropdown',{attrs:{"placeholder":"format","options":_vm.formatOptions,"searchable":"","colors":{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                },"value":_vm.formatValue,"config":{ label: 'format', trackBy: 'format' },"maxWidth":"100%","minWidth":"100%","minHeight":"20%"},on:{"input":(value) => _vm.updateFormatValue(value)}})],1),_c('div',{staticClass:"flex gap-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.masterCurrency),expression:"masterCurrency"}],staticClass:"checkbox mt-8",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.masterCurrency)?_vm._i(_vm.masterCurrency,null)>-1:(_vm.masterCurrency)},on:{"change":function($event){var $$a=_vm.masterCurrency,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.masterCurrency=$$a.concat([$$v]))}else{$$i>-1&&(_vm.masterCurrency=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.masterCurrency=$$c}}}}),_c('label',{staticClass:"cursor-pointer label mt-7"},[_c('span',{staticClass:"label-text"},[_vm._v("Master Currency")])])])],1),_c('div',{staticClass:"flex justify-end max-w-full"},[_c('Button',{staticClass:"btn-primary float-right mt-4",attrs:{"text":"Create"},on:{"click":function($event){return _vm.createCurrency(hasErrors)}}})],1)]}}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }