<template>
  <div>
    <div class="p-1">
      <sub-header :backButton="true"></sub-header>
    </div>
    <div class="p-1">
      <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Create Currency
        </div>
        <div class="py-3 px-6">
          <FormulateForm #default="{ hasErrors }">
            <div class="grid grid-cols-3 gap-x-6">
              <div class="h-6">
                <h2 class="mb-1 mt-1">Currency Code</h2>
                <Dropdown
                  placeholder="currency codes"
                  :options="currencyCodes"
                  searchable
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :value="currencyCode"
                  @input="(value) => updateCode(value)"
                  :config="{ label: 'currency_code', trackBy: 'currency_code' }"
                  maxWidth="100%"
                  minWidth="100%"
                  minHeight="20%"
                  :loading="loadingCurrency"
                />
              </div>
              <FormulateInput
                class="w-full"
                label="Currency Symbol"
                placeholder="currency symbol"
                validation="required"
                type="text"
                v-model="currencyInformation.currency_symbol"
                disabled
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
              />
              <FormulateInput
                validation="required"
                class="w-full"
                label="Currency Name"
                placeholder="currency name"
                v-model="currencyInformation.currency_name"
                type="text"
                disabled
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
              />
              <div class="h-6">
                <h2 class="mb-1 mt-1">Decimal Places</h2>
                <Dropdown
                  placeholder="decimal places"
                  :options="decimalPlaces"
                  searchable
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :value="decimalValue"
                  @input="(value) => updateDecimalValue(value)"
                  :config="{ label: 'decimal', trackBy: 'decimal' }"
                  maxWidth="100%"
                  minWidth="100%"
                  minHeight="20%"
                />
              </div>
              <div class="h-6">
                <h2 class="mb-1 mt-1">Format</h2>
                <Dropdown
                  placeholder="format"
                  :options="formatOptions"
                  searchable
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :value="formatValue"
                  @input="(value) => updateFormatValue(value)"
                  :config="{ label: 'format', trackBy: 'format' }"
                  maxWidth="100%"
                  minWidth="100%"
                  minHeight="20%"
                />
              </div>
              <div class="flex gap-4">
                <input
                  type="checkbox"
                  class="checkbox mt-8"
                  v-model="masterCurrency"
                />
                <label class="cursor-pointer label mt-7">
                  <span class="label-text">Master Currency</span>
                </label>
              </div>
            </div>
            <div class="flex justify-end max-w-full">
              <Button
                @click="createCurrency(hasErrors)"
                class="btn-primary float-right mt-4"
                text="Create"
              />
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import Dropdown from "@shared/components/dropdown-base";
import { getCurrencyCodes, createCurrency } from "./services";

export default {
  name: "createCurrency",
  data: function () {
    return {
      currencyCodes: [],
      masterCurrency: false,
      currencyCode: null,
      loadingCurrency: false,
      currencyInformation: {
        currency_symbol: null,
        currency_name: null,
      },
      decimalPlaces: [
        { decimal: 1 },
        { decimal: 2 },
        { decimal: 3 },
        { decimal: 4 },
      ],
      format: null,
      decimalValue: null,
      formatValue: null,
      formatOptions: [
        { format: "123,456,789.01" },
        { format: "12,34,56,789.01" },
        { format: "123456,789.01" },
        { format: "123456789.01" },
      ],
    };
  },
  components: {
    SubHeader,
    Button,
    Dropdown,
  },
  async mounted() {
    await this.setCurrencyCodes();
  },
  methods: {
    async createCurrency(hasErrors) {
      if (!hasErrors) {
        if (!this.decimalValue || !this.formatValue) {
          this.$toast.error("Please select format and decimal value");
        } else {
          try {
            const payload = {
              currency_id: this.currencyInformation.id,
              base_currency: false,
              master_currency: this.masterCurrency,
              format: this.formatValue.format,
              decimal_place: this.decimalValue.decimal,
            };
            await createCurrency(payload);
            this.$toast.success("Currency created");
            this.$router.back()
          } catch (err) {
            if (err?.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("Something went wrong");
            }
          }
        }
      } else {
        this.$toast.error("Please fill all required fields");
      }
    },
    async setCurrencyCodes() {
      try {
        const { data } = await getCurrencyCodes();
        // ideally this should comes from backend in alphabetically order
        // Sorted the currency code alphabetically
        const sortedAlphabetically = await data.data.sort((a, b) => {
          if (a.currency_code.toLowerCase() < b.currency_code.toLowerCase()) {
            return -1;
          }
          if (a.currency_code.toLowerCase() > b.currency_code.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        this.currencyCodes = sortedAlphabetically;
      } catch (error) {
        console.error(error);
      }
    },
    updateCode(value) {
      this.currencyInformation = value;
    },
    updateFormatValue(value) {
      this.formatValue = value;
    },
    updateDecimalValue(value) {
      this.decimalValue = value;
    },
  },
};
</script>
<style>
.formulate-input[data-classification="text"] input{
      border-radius: 0.5rem;
}

</style>